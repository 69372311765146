import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl, moment } from 'helpers'
import {
  Img,
  FormattedMessage,
  Layout,
  Section,
  Container,
  Link,
  Flex,
  Case,
  BrandLogo,
  Background,
  Content,
} from 'components'
import ImgP from 'gatsby-image/withIEPolyfill'

const CaseMainSection = styled.div`
  position: relative;
  z-index: 1;
`

const CaseTemplate = ({
  data: { allContentfulCase },
  intl: { locale },
  ...props
}) => {
  const {
    id,
    slug,
    title,
    date,

    tags,
    timeline,
    client,
    website,

    logo,
    background,

    content,
  } = allContentfulCase.edges.filter(
    ({ node: { node_locale } }) => node_locale === locale
  )[0].node
  return (
    <Layout simple>
      <Section fullpage style={{ backgroundColor: '#333' }}>
        <Container>
          <CaseMainSection>
            <Flex column>
              {logo && (
                <BrandLogo>
                  <ImgP fluid={logo.fluid} objectFit="contain" />
                </BrandLogo>
              )}
              <h1>{title}</h1>
              <h3>{moment(date).format('LL')}</h3>
              <p>{tags}</p>
            </Flex>
          </CaseMainSection>
        </Container>
        {background && (
          <Background dark>
            <Img fluid={background.fluid} />
          </Background>
        )}
      </Section>
      <Section>
        <Container>
          {/* <h1>{title}</h1> */}
          <div
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(CaseTemplate)

export const query = graphql`
  query($slug: String!) {
    allContentfulCase(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          title
          tags
          date

          timeline
          client
          website

          content {
            childMarkdownRemark {
              html
            }
          }

          logo {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }

          background {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }

          description
          descriptionImage {
            id
            fluid {
              ...GatsbyContentfulFluid
            }
          }

          node_locale
        }
      }
    }
  }
`
